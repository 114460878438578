import { getLocalObject, setLocalObject } from '@ui/helpers'

export class LocalVariant {
  static variantKey = '_selless_variant'

  static get variant(): Types.OrderItem {
    return process.browser ? getLocalObject(this.variantKey) : null
  }

  static set variant(variant: Types.OrderItem) {
    if (process.browser) {
      setLocalObject(this.variantKey, variant)
    }
  }
}

export default LocalVariant
