import {
  genSid,
  isApplePayActive,
  useCheckoutService,
  useEventFbId,
  useGlobalState,
  useLocalIsBuyNow,
  useLocalIsPuchase,
  useLocalOrderId,
  useLocalSessionId,
  useLocalShippingAddress,
  useOrderService,
  useRefDep,
  useTrackingService
} from '@libs/client'
import { CartItem, CustomerAddress, UpdatedCart } from '@libs/client/order/services'
import { ExpressCheckoutElement, useElements, useStripe } from '@stripe/react-stripe-js'
import {
  LineItem,
  ShippingRate,
  StripeElementsOptionsMode,
  StripeExpressCheckoutElementClickEvent,
  StripeExpressCheckoutElementConfirmEvent,
  StripeExpressCheckoutElementOptions,
  StripeExpressCheckoutElementShippingAddressChangeEvent,
  StripeExpressCheckoutElementShippingRateChangeEvent
} from '@stripe/stripe-js'
import { logger } from '@ui/analytics'
import getLogRocket from '@ui/analytics/logRocket'
import { ttqTrackSingleIdentify } from '@ui/helpers'
import {
  useAnalytics,
  useCurrentOrder,
  useFbp,
  usePinterest,
  useRedirect,
  useSetting,
  useSnapChat,
  useTtq
} from '@ui/hooks'
import { useAxon } from '@ui/hooks/useAxon'
import { useGtag } from '@ui/hooks/useGtag'
import { LocalContact } from '@ui/modules'
import { useRouter } from 'next/router'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import css from 'styled-jsx/css'
import { initAddress } from '../checkout/CheckoutForm'
import { useNotiContext } from './Notification'
import { method_type } from '@libs/common/models/constant'

type Props = {
  className?: string
  setHasApplePayBtn: Dispatch<SetStateAction<boolean>>
  isBuyNow?: boolean
  version?: string
}

const ApplePayButton: FC<Props> = ({ className, setHasApplePayBtn, isBuyNow = false, version }) => {
  const router = useRouter()
  const stripe = useStripe()
  const elements = useElements()
  const { logRocket } = getLogRocket()

  const { updateApplePayTaxAmount, updateTaxAmount } = useOrderService()
  const { payByApple } = useCheckoutService()

  const redirect = useRedirect()
  const [sellpageSetting] = useSetting('sellpage')
  const [localShipping, setLocalShipping] = useLocalShippingAddress()
  const [currentOrder, setCurrentOrder] = useCurrentOrder()
  const { notiDispatch } = useNotiContext()
  const [address, setAddress] = useState<CustomerAddress>(initAddress({}))
  const [, setLocalSissionId] = useLocalSessionId()
  const [, setLocalIsPuchase] = useLocalIsPuchase()
  const [localOrderId] = useLocalOrderId()

  const [loading, setLoading] = useState(true)
  const [isShowButton, setIsShowButton] = useState(false)

  const [, setEventFbId] = useEventFbId()
  const { fbpTrackAddPaymentInfo, fbpTrackPurchase, fbpInitAdvanceMatching } = useFbp()
  const { pinterestTrackPurchase } = usePinterest()
  const [, setApplePayActive] = useGlobalState(isApplePayActive)
  const [applePayType, setApplePayType] = useState('')
  const { snapchatTrackAddPaymentInfo, snapchatTrackPurchase } = useSnapChat()
  const { ttqTrackAddBilling, ttqTrackPurchase, ttqTrackIdentify } = useTtq()
  const { trackingInitCheckoutApplePay } = useTrackingService()
  const {
    gtagTrackAddPaymentInfo,
    gtagTrackAddShippingInfo,
    gtagTrackPurchase,
    gtagTrackConversionPurchase,
    gtagEnhanceConversion
  } = useGtag()
  const { axonTrackPurchase } = useAxon()
  const [storeSetting] = useSetting('store')
  const [localIsBuyNow, setLocalBuyNow] = useLocalIsBuyNow()
  const localIsBuyNowRef = useRefDep(localIsBuyNow)
  const isBuyNowCurrent =
    (typeof isBuyNow === 'boolean' ? isBuyNow : localIsBuyNowRef.current) || false
  const isMobile = process?.browser && window?.innerWidth <= 768

  useEffect(() => {
    setLocalBuyNow(isBuyNow)
  }, [isBuyNow])

  const title = storeSetting?.general?.name || ''
  let url = storeSetting?.general?.domain || ''

  url = url.replace(/^(https?:\/\/)?/, 'https://')

  const domain = url.replace(/^https?:\/\/?/, '')
  const shop = title || domain

  const analytics = useAnalytics()
  const googleConversion = `AW-${analytics?.google_conversion_id || ''}/${
    analytics?.google_conversion_label || ''
  }`

  useEffect(() => {
    const addr = initAddress(currentOrder?.shipping ?? localShipping ?? {})
    setAddress(addr)
  }, [])

  useEffect(() => {
    /** ✅ Check Apple Pay Support & Send Tracking Event */
    if (stripe) {
      const paymentRequest = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: { label: storeSetting?.general?.name || '', amount: 1000 },
        requestPayerName: true,
        requestPayerEmail: true
      })

      paymentRequest.canMakePayment().then((result) => {
        if (result?.applePay) {
          setHasApplePayBtn(true)
          setIsShowButton(true)
          setLoading(false)
          setApplePayType('active')
        } else {
          setApplePayType('inactive')
        }
      })
    }
  }, [stripe])

  useEffect(() => {
    if (isShowButton && applePayType) {
      if (applePayType == 'active') {
        logger.logProductEvent('apple_pay_active')
        setApplePayActive('active')
      } else {
        logger.logProductEvent('apple_pay_inactive')
        setApplePayActive('inactive')
      }
    }
  }, [isShowButton, applePayType, isBuyNow])

  const createLineItems = (updateOrder?: UpdatedCart) => {
    const productVariants: LineItem[] = currentOrder
      ? (currentOrder?.items?.map((item: any) => ({
          name: `${item?.quantity} ${
            sellpageSetting?.settings?.general?.title ?? ''
          } [${Object.values(item?.properties ?? {})?.join(' - ')}]`,
          amount: roundedNumber((item?.price ?? 0) * (item?.quantity ?? 0) * 100)
        })) as LineItem[])
      : []

    const dynamicOrder = updateOrder ?? currentOrder

    return [
      ...productVariants,
      ...createChargeItem('Shipping', dynamicOrder?.shipping_amount ?? 0),
      ...createChargeItem('Discount', -(dynamicOrder?.discount_amount ?? 0)),
      ...createChargeItem('Tax', dynamicOrder?.tax_amount)
    ] as LineItem[]
  }

  const shippingRates: Array<ShippingRate> = [
    {
      id: sellpageSetting?.settings?.shipping?.code ?? 'Secured Shipping',
      amount: roundedNumber((currentOrder?.shipping_amount ?? 0) * 100),
      displayName: 'Shipping'
    }
  ]

  async function onClickPayButton(event: StripeExpressCheckoutElementClickEvent) {
    if (!isBuyNowCurrent) {
      logger.logProductEvent('checkout_apple_pay_cart')
    } else {
      logger.logProductEvent('checkout_apple_pay_buynow')
    }
    trackingInitCheckoutApplePay(isBuyNowCurrent)
    event.resolve({
      business: {
        name: shop
      },
      billingAddressRequired: true,
      shippingAddressRequired: true,
      emailRequired: true,
      phoneNumberRequired: true,
      shippingRates,
      lineItems: createLineItems()
    })
  }

  async function _onShippingAddressChange(
    event: StripeExpressCheckoutElementShippingAddressChangeEvent
  ) {
    try {
      const updateOrder = await updateApplePayTaxAmount({
        address: {
          ...address,
          city: event.address?.city,
          state: event.address?.state,
          postal_code: event.address?.postal_code
        },
        orderItems: currentOrder?.items as CartItem[],
        orderId: currentOrder?.id || localOrderId
      })

      setAddress({
        ...address,
        city: event.address?.city,
        state: event.address?.state,
        postal_code: event.address?.postal_code
      })
      const newLineItems = createLineItems(updateOrder)
      setCurrentOrder({
        ...currentOrder,
        ...updateOrder,
        items: currentOrder?.items ?? []
      })

      event.resolve({
        shippingRates: [
          {
            id: sellpageSetting?.settings?.shipping?.code ?? 'Secured Shipping',
            amount: roundedNumber((updateOrder?.shipping_amount ?? 0) * 100),
            displayName: 'Shipping'
          }
        ],
        lineItems: newLineItems
      })
    } catch (error) {
      console.log('error while processing')
      // throw new Error(error)
    }
  }

  function _onShippingRateChange({ resolve }: StripeExpressCheckoutElementShippingRateChangeEvent) {
    resolve({
      shippingRates,
      lineItems: createLineItems()
    })
  }

  async function onConfirmApplePay(event: StripeExpressCheckoutElementConfirmEvent) {
    try {
      const addr: CustomerAddress | undefined = {
        ...address,
        city: event.shippingAddress?.address?.city,
        state: event.shippingAddress?.address?.state,
        postal_code: event.shippingAddress?.address?.postal_code,
        address1: event.shippingAddress?.address?.line1 ?? '',
        address2: event.shippingAddress?.address?.line2 ?? '',
        email: event.billingDetails?.email ?? '',
        phone: event.billingDetails?.phone ?? '',
        first_name: event.shippingAddress?.name,
        country: 'United States'
      }
      setAddress(addr)
      setLocalShipping({
        ...localShipping,
        ...addr
      })
      setCurrentOrder({
        ...currentOrder,
        shipping: {
          ...localShipping,
          ...addr
        }
      })
      if (stripe && elements) {
        // try {
        logger.logProductEvent('apple_pay_add_payment_info')

        // await validateAddress(addr)

        ttqTrackSingleIdentify(addr)

        fbpTrackAddPaymentInfo(currentOrder?.items, currentOrder?.items_amount)
        ttqTrackAddBilling(currentOrder?.items, currentOrder?.items_amount)
        gtagTrackAddPaymentInfo(currentOrder?.items, currentOrder?.items_amount, 'Stripe')
        gtagTrackAddShippingInfo(currentOrder?.items, currentOrder?.items_amount)
        snapchatTrackAddPaymentInfo(currentOrder?.items, currentOrder?.items_amount)

        const result = await payByApple({
          stripe,
          elements,
          shipping: addr
        })

        // TODO: Pixel
        fbpTrackPurchase(result?.order.items, result?.order.amount)
        fbpInitAdvanceMatching(result?.order?.shipping)
        pinterestTrackPurchase(result?.order.amount)
        snapchatTrackPurchase(result?.order.items, result?.order.amount)
        ttqTrackIdentify(result?.order?.shipping)
        ttqTrackPurchase(result?.order.items, result?.order.amount)
        gtagTrackPurchase(result?.order)
        axonTrackPurchase(result?.order)
        gtagTrackConversionPurchase(googleConversion, result?.order)
        gtagEnhanceConversion(result?.order)
        setLocalSissionId(genSid())
        setEventFbId(genSid())
        setLocalIsPuchase(true)
        logRocket?.identify({
          email: address.email,
          firstName: address.first_name,
          lastName: address.last_name
        })

        LocalContact.add({
          order_name: result?.order.code,
          email: address.email
        })

        if (!isBuyNowCurrent) {
          logger.logProductEvent('apple_pay_payment_success')
        } else {
          logger.logProductEvent('payment_success_apple_pay_buynow')
        }
        if (router.query?.afs || '') {
          logger.logProductEvent('after_sale_apple_pay_payment_success')
        }

        notiDispatch({
          payload: {
            content: 'Your payment was successfully',
            type: 'is-success',
            timeout: 1
          },
          type: 'REMOVE_ALL_AND_ADD'
        })

        setTimeout(() => {
          const query = router.query
          delete query.vb
          delete query.subpath
          redirect('/thankyou', {
            query: { ...query, afs: result?.order.id },
            keepQuery: false
          })
        }, 1000)
        // } catch (error) {
        //   console.error(error)
        // }
      }
    } catch (err: any) {
      event.paymentFailed({ reason: 'fail' })

      const message = err?.response?.data?.detail || err.message
      const errCode = err?.response?.status
      if (errCode === 404 || errCode === 402 || errCode === 550 || errCode === 555) {
        notiDispatch({
          payload: {
            content: message,
            type: 'is-danger'
          },
          type: 'REMOVE_ALL_AND_ADD'
        })
      } else {
        notiDispatch({
          payload: { content: message, type: 'is-danger' },
          type: 'REMOVE_ALL_AND_ADD'
        })
        // console.error('checkout error', err?.response?.data)
      }
    }
    logger.logProductEvent('payment_failure')

    // notiDispatch({
    //   payload: {
    //     content: 'Error while processing',
    //     type: 'is-danger'
    //   },
    //   type: 'REMOVE_ALL_AND_ADD'
    // })
  }

  async function onCancel() {
    const res = await updateTaxAmount(localShipping || {}, method_type.apple_pay)
    setCurrentOrder({
      ...currentOrder,
      ...res,
      items: currentOrder?.items ?? []
    })
  }

  const buttonHeight = isBuyNow
    ? isMobile
      ? 52
      : 55
    : version == 'apv5' ||
      version == 'apv51' ||
      version == 'apv6' ||
      version == 'b25v24' ||
      version == 'a25v26'
    ? 55
    : applePayElementOptions?.buttonHeight

  return (
    <div className="apple-pay-container">
      <style jsx>{applePayStyles}</style>
      <div className={`apple-pay-button ${className}`}>
        {loading ? (
          <button className="apple-pay-element button is-fullwidth is-loading is-warning loading-button mb-1" />
        ) : null}

        <div
          style={{
            display: isShowButton && applePayType == 'active' ? 'block' : 'none',
            marginTop:
              isShowButton &&
              applePayType == 'active' &&
              (version == 'apv5' || version == 'apv51' || version == 'apv6' || version == 'b25v24')
                ? '0px'
                : '8px'
          }}
          className={isShowButton && applePayType == 'active' ? 'apple-pay-element' : ''}
        >
          <ExpressCheckoutElement
            onConfirm={onConfirmApplePay}
            onShippingAddressChange={_onShippingAddressChange}
            onShippingRateChange={_onShippingRateChange}
            onClick={onClickPayButton}
            options={{
              ...applePayElementOptions,
              buttonHeight: buttonHeight,
              buttonType: {
                applePay: isBuyNow
                  ? version == 'apv5' ||
                    version == 'apv51' ||
                    version == 'apv6' ||
                    version == 'b25v24' ||
                    version == 'a25v26'
                    ? 'buy'
                    : 'check-out'
                  : 'buy'
              }
            }}
            onReady={({ availablePaymentMethods }) => {
              // sleep(1000)
              setLoading(false)
              if (availablePaymentMethods) {
                setHasApplePayBtn(true)
                setIsShowButton(true)
              }
            }}
            onCancel={onCancel}
          />
        </div>
      </div>
    </div>
  )
}

export default ApplePayButton

const applePayStyles = css`
  .apple-pay-button {
    .loading-button {
      background-color: #000 !important;
      height: 52px;
    }
    .button.is-warning.is-loading::after {
      border-color: transparent transparent rgba(255, 255, 255, 0.9) rgba(255, 255, 255, 0.9) !important;
    }
  }
`
const createChargeItem = (label: string, amount?: number, extra?: Record<string, any>) => {
  return amount
    ? [
        {
          name: label,
          amount: roundedNumber((amount ?? 0) * 100),
          ...extra
        }
      ]
    : []
}

function roundedNumber(n: number): number {
  return Number(n.toFixed(0))
}

const applePayElementOptions: StripeExpressCheckoutElementOptions = {
  wallets: {
    applePay: 'always',
    googlePay: 'never'
  },
  layout: {
    overflow: 'auto',
    maxColumns: 1,
    maxRows: 1
  },
  buttonHeight: 50,
  buttonType: {
    applePay: 'buy'
  }
}

export const appleExpressElementOptions: StripeElementsOptionsMode = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css2?family=Poppins&display=swap'
    }
  ],
  mode: 'payment',
  currency: 'usd',
  paymentMethodTypes: ['card']
}

export const preCalculateOrderAmount = ({
  currentAmount,
  shipping_amount,
  tax_amount,
  discount_amount
}: {
  currentAmount: number
  shipping_amount: number
  tax_amount: number
  discount_amount: number
}) => {
  return currentAmount + shipping_amount + tax_amount - discount_amount
}
