import { useEffect, useState } from 'react'
import css from 'styled-jsx/css'
import { getCurrency, iconPath } from '@ui/helpers'
import { usePaygates } from '@ui/hooks'
import { logger } from '@ui/analytics'

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js'
import { ICheckoutForm } from './CheckoutForm'
import { Stripe, StripeElements } from '@stripe/stripe-js'
import { useIsCollectTax, useIsCompletedCard } from '@libs/client'

interface CardFormStripeProps extends ICheckoutForm {
  onStripePay: (stripe: Stripe, elements: StripeElements) => void
  isPaying: boolean
  version?: string
}

const CardFormStripe = ({
  isPaidOrder,
  currentOrder,
  onStripePay,
  isPaying,
  version
}: CardFormStripeProps) => {
  const [cardNumberError, setCardNumberError] = useState(``)
  const [cardExpiryError, setCardExpiryError] = useState(``)
  const [cardCvcError, setCardCvcError] = useState(``)
  const [cardComplete, setCardComplete] = useState({
    number: false,
    expiry: false,
    cvc: false
  })
  // const [flagComplete, setFlagComplete] = useState(false)
  const [flagComplete, setFlagComplete] = useIsCompletedCard()
  const [flag, setFlag] = useState(true)
  const paygates = usePaygates()
  const [isCollect] = useIsCollectTax()
  const listSupportCard = paygates?.creditcard?.cards
  const stripe = useStripe()
  const elements = useElements()
  const style = {
    base: {
      color: '#363636',
      fontWeight: 400,
      ...(version == 'v13' ? { boxShadow: 'inset 0px 0px 8px rgba(34, 34, 34, 0.1)' } : {}),
      lineHeight: '24px',
      fontFamily: 'Poppins, sans-serif',
      fontSize: '16px',
      '::placeholder': {
        fontFamily: 'Poppins, sans-serif',
        lineHeight: '24px',
        color: `${version == 'v13' ? '#818181' : '#b5b5b5'}`,
        fontWeight: 400,
        fontSize: '16px'
      }
    },
    invalid: {
      lineHeight: '16px',
      color: '#363636',
      ':focus': {
        color: '#363636'
      }
    }
  }

  const handleSubmitStripe = async (event: any) => {
    event.preventDefault()
    if (flag) {
      setCardNumberError(`"Card number" is not allowed to be empty`)
      setCardExpiryError(`"Card expiry" is not allowed to be empty`)
      setCardCvcError(`"Card cvc" is not allowed to be empty`)
      return
    }
    if (cardNumberError || cardExpiryError || cardCvcError || elements === null) {
      return
    } else {
      if (elements && elements.getElement(CardNumberElement)) {
        onStripePay(stripe as Stripe, elements)
      }
    }
  }

  useEffect(() => {
    if (cardComplete?.number && cardComplete?.expiry && cardComplete?.cvc) {
      if (
        version === 'v145' ||
        version === 'vta4' ||
        version === 'apv1' ||
        version === 'apv2' ||
        version === 'apv3' ||
        version === 'apv21' ||
        version === 'm144' ||
        version === 'ab144' ||
        version === 'd144' ||
        version === 'ac144' ||
        version === 'apv5' ||
        version === 'apv51' ||
        version === 'apv6' ||
        version === 'b25v24' ||
        version == 'a25v26'
      ) {
        setFlagComplete(true)
      }
      logger.logProductEvent('fill_card_completed')
    }
  }, [version, cardComplete])

  return (
    <div style={{ marginBottom: '20px' }}>
      <style jsx global>
        {globalStyle}
      </style>
      <div>
        <div className="card-infomation mb-3">
          <div style={{ fontSize: '15px' }}>Card information</div>
          <div>
            {listSupportCard &&
              listSupportCard.map((card) => (
                <figure key={card} className="image is-pulled-right label__icon">
                  <img src={iconPath(`${card.toLowerCase()}.svg`)} alt="card-list" />
                </figure>
              ))}
          </div>
        </div>

        <div className={version == 'v13' ? 'v13' : ''}>
          <fieldset
            className="FormGroup"
            style={{ border: cardNumberError ? '1px solid #f14668' : '1px solid #dbdbdb' }}
          >
            <div className="FormRow">
              <CardNumberElement
                options={{
                  iconStyle: 'solid',
                  placeholder: version == 'v13' ? 'Enter card number' : 'Card number',
                  style: style
                }}
                onChange={(e: any) => {
                  setCardComplete((prev) => ({ ...prev, number: e?.complete }))
                  setFlag(false)
                  if (e?.error === undefined) {
                    if (e?.empty) {
                      setCardNumberError(`"Card number" is not allowed to be empty`)
                    } else {
                      setCardNumberError('')
                    }
                  } else {
                    setCardNumberError(e?.error?.message)
                  }
                }}
              />
            </div>
          </fieldset>
          <p style={{ color: '#f14668', fontSize: '0.74rem', marginTop: '0.25rem' }}>
            {cardNumberError}
          </p>

          <div className="expiry-cvv">
            <fieldset
              className="card-expiry-element FormGroup"
              style={{ border: cardExpiryError ? '1px solid #f14668' : ' 1px solid #dbdbdb' }}
            >
              <div className="FormRow">
                <CardExpiryElement
                  options={{
                    placeholder: 'Expiry date (MM / YY)',
                    style: style
                  }}
                  onChange={(e: any) => {
                    setFlag(false)
                    setCardComplete((prev) => ({ ...prev, expiry: e?.complete }))
                    if (e?.error === undefined) {
                      if (e?.empty) {
                        setCardExpiryError(`"Card expiry" is not allowed to be empty`)
                      } else {
                        setCardExpiryError('')
                      }
                    } else {
                      setCardExpiryError(e?.error?.message)
                    }
                  }}
                />
              </div>
            </fieldset>

            <fieldset
              className="card-expiry-element FormGroup"
              style={{ border: cardCvcError ? '1px solid #f14668' : ' 1px solid #dbdbdb' }}
            >
              <div className="FormRow">
                <CardCvcElement
                  options={{
                    placeholder: 'CVV',
                    style: style
                  }}
                  onChange={(e: any) => {
                    setFlag(false)
                    setCardComplete((prev) => ({ ...prev, cvc: e?.complete }))
                    if (e?.error === undefined) {
                      if (e?.empty) {
                        setCardCvcError(`"Card cvc" is not allowed to be empty`)
                      } else {
                        setCardCvcError('')
                      }
                    } else {
                      setCardCvcError(e?.error?.message)
                    }
                  }}
                />
              </div>
            </fieldset>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{ color: '#f14668', fontSize: '0.74rem', marginTop: '0.25rem', width: '50%' }}
            >
              {cardExpiryError}
            </p>
            <p
              style={{
                color: '#f14668',
                fontSize: '0.74rem',
                marginTop: '0.25rem',
                width: '50%',
                paddingLeft: '5px'
              }}
            >
              {cardCvcError}
            </p>
          </div>
        </div>
      </div>
      <div className="checkout-footer">
        {((version !== 'vta4' &&
          version !== 'v145' &&
          version !== 'apv1' &&
          version !== 'apv2' &&
          version !== 'apv21' &&
          version !== 'apv3' &&
          version !== 'ac144' &&
          version !== 'apv5' &&
          version !== 'apv51' &&
          version !== 'apv6' &&
          version !== 'm144' &&
          version !== 'ab144' &&
          version !== 'b25v24' &&
          version !== 'a25v26' &&
          version !== 'd144') ||
          flagComplete) &&
          isCollect &&
          currentOrder?.tax_engine && (
            <div className="tax-amount">
              {version == 'vta4' ||
              version == 'vta3' ||
              version == 'vta31' ||
              version == 'vta2' ||
              version == 'vta1' ? (
                <>
                  {currentOrder?.tax_amount ? (
                    <>
                      <span className="tax-amount__number">
                        {getCurrency(
                          (currentOrder?.tax_amount || 0) + (currentOrder?.handling_fee || 0)
                        )}
                      </span>{' '}
                      of handling fee and sales tax have been added to total order
                    </>
                  ) : (
                    <>
                      <span className="tax-amount__number">
                        {getCurrency(
                          (currentOrder?.tax_amount || 0) + (currentOrder?.handling_fee || 0)
                        )}
                      </span>{' '}
                      of handling fee has been added to total order
                    </>
                  )}
                </>
              ) : (
                <>
                  {currentOrder?.tax_amount ? (
                    <>
                      <span className="tax-amount__number">
                        {getCurrency(currentOrder?.tax_amount || 0)}
                      </span>{' '}
                      of sales tax has been added to total order
                    </>
                  ) : (
                    <>
                      Based on your address, sales tax is{' '}
                      <span className="tax-amount__number">
                        {getCurrency(currentOrder?.tax_amount || 0)}
                      </span>
                    </>
                  )}
                </>
              )}
            </div>
          )}
        <button
          className={`checkout-button button is-fullwidth is-primary ${
            isPaying ? 'is-loading' : ''
          }`}
          disabled={!stripe || !elements}
          onClick={handleSubmitStripe}
        >
          {isPaidOrder && <strong>Next</strong>}
          {!isPaidOrder && (
            <>
              <figure className="image is-16x16 mb-1 mr-4">
                <img src={iconPath('lock.svg')} alt="lock" />
              </figure>
              {version == 'vta4' && !flagComplete ? (
                <strong>{`Pay ${getCurrency(
                  (currentOrder?.amount ?? 0) -
                    ((currentOrder?.tax_amount ?? 0) + (currentOrder?.handling_fee ?? 0))
                )} now`}</strong>
              ) : (version == 'v145' ||
                  version == 'apv1' ||
                  version == 'apv2' ||
                  version == 'apv21' ||
                  version == 'apv3' ||
                  version == 'ac144' ||
                  version == 'apv5' ||
                  version == 'apv51' ||
                  version == 'apv6' ||
                  version == 'm144' ||
                  version == 'ab144' ||
                  version == 'ab144' ||
                  version == 'b25v24' ||
                  version == 'a25v26') &&
                !flagComplete ? (
                <strong>{`Pay ${getCurrency(
                  (currentOrder?.amount ?? 0) - (currentOrder?.tax_amount ?? 0)
                )} now`}</strong>
              ) : (
                <strong>{`Pay ${getCurrency(currentOrder?.amount ?? 0)} now`}</strong>
              )}
            </>
          )}
        </button>
      </div>

      <style jsx>{formInputStyle}</style>
    </div>
  )
}

export default CardFormStripe

const formInputStyle = css.global`
  .FormGroup {
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    padding: 15px 20px;
    margin-top: 10px;
    background-color: white;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  }

  .v13 {
    .FormGroup {
      box-shadow: inset 0px 0px 8px rgba(34, 34, 34, 0.1);
      border-color: #d4d4d4;
      padding: 15px 12px;
    }
  }

  .expiry-cvv {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .card-expiry-element {
    width: 49.3%;
  }

  .card-infomation {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-weight: 500;
    font-size: 1rem;
    line-height: 24px;
  }

  .image-card {
    display: flex;
    align-items: center;
  }

  .checkout-footer {
    margin-top: 16px;
  }

  .tax-amount {
    text-align: center;
    font-size: 15px;
    line-height: 24px;
    color: #363636;
    padding: 0 10px 10px 10px;

    &__number {
      font-weight: 600;
    }
  }
`

const globalStyle = css.global`
  .card-infomation {
    .label__icon {
      &:not(:last-child) {
        margin-left: 6px;
      }

      img {
        width: unset;
        height: 20px;
      }
    }
  }
`
