import { REMOVE_ALL_AND_ADD, useNotiContext } from '@notify'
import React, { useEffect, useRef, useState } from 'react'
import {
  useAnalytics,
  useDeepEffect,
  useFbp,
  usePinterest,
  useRedirect,
  useSetting,
  useSnapChat,
  useSyncedState,
  useTtq,
  useVersionCookie
} from '@ui/hooks'

import AddressForm from '@ui/components/checkout/AddressForm'
// import CardForm from '@ui/components/checkout/CardForm'
import CardFormStripe from '@ui/components/checkout/CardFormStripe'
import { logger } from '@ui/analytics'
import css from 'styled-jsx/css'
import { getCurrency } from '@ui/helpers/utilities'
import { cartMapping, checkZipCode, iconPath } from '@ui/helpers'
import { validateAddress, validateAddressByTax, validateAddressField } from '@ui/validators/address'
import { validateCard, validateCardField } from '@ui/validators/card'
import {
  CustomerAddress,
  CustomerAddressField,
  PayCreditcardRequest
} from '@libs/client/order/services'
import {
  genSid,
  useCheckoutService,
  useEventFbId,
  useIsCollectTax,
  useLocalIsPaypalThankyou,
  useLocalIsPuchase,
  useLocalSessionId,
  useLocalShippingAddress,
  useOrderService,
  useRefDep
} from '@libs/client'
import { useDebounce, usePaygates } from '@ui/hooks'
import { useGtag } from '@ui/hooks/useGtag'
import getLogRocket from '@ui/analytics/logRocket'
import { LocalContact } from '@ui/modules'

import { Elements } from '@stripe/react-stripe-js'
import { Stripe, StripeElements } from '@stripe/stripe-js'
import { OtpOut } from './OtpOut'
import { method_type, paygate_adapter } from '@libs/common/models/constant'
import CardFormBraintree from './CardFormBraintree'
import { HostedFields, ThreeDSecure } from 'braintree-web'
import PaypalButton from '../shared/PaypalButton'
import { useRouter } from 'next/router'
import { useAxon } from '@ui/hooks/useAxon'
import { useLoadStripe } from '@libs/client/order/hooks/useLoadStripe'

export interface ICheckoutForm extends React.AllHTMLAttributes<HTMLFormElement> {
  isPaidOrder: boolean
  isPreview: boolean
  isPurchase: boolean
  isShowPaypalButtonFallBack?: boolean
  currentOrder: Types.Order | null
}

export function initAddress(address?: CustomerAddress): CustomerAddress {
  return {
    country: 'United States',
    country_code: address?.country_code ?? 'US',
    email: address?.email ?? '',
    first_name: address?.first_name ?? '',
    last_name: address?.last_name ?? '',
    address1: address?.address1 ?? '',
    address2: address?.address2 ?? '',
    city: address?.city ?? '',
    state: address?.state ?? '',
    postal_code: address?.postal_code ?? '',
    phone: address?.phone ?? ''
  }
}

function initCard(): PayCreditcardRequest {
  return {
    card_number: '',
    expiry_date: '',
    cvc: ''
  }
}

export default function CheckoutForm({
  isPaidOrder = false,
  isPreview = true,
  isPurchase = true,
  isShowPaypalButtonFallBack = false,
  currentOrder,
  ...props
}: ICheckoutForm) {
  const router = useRouter()
  const redirect = useRedirect()
  const { notiDispatch } = useNotiContext()
  const [sellpageSetting] = useSetting('sellpage')

  const { updateShipping, updateTaxAmount } = useOrderService()
  const { payByCard, payByStripe, payByBraintree, payByCardBraintree } = useCheckoutService({
    cartMapping: (cart) => cartMapping(cart, sellpageSetting?.variants?.items ?? {})
  })
  const [localShipping, setLocalShipping] = useLocalShippingAddress()
  const [, setLocalSissionId] = useLocalSessionId()
  const [, setEventFbId] = useEventFbId()
  const { fbpTrackAddPaymentInfo, fbpTrackPurchase, fbpInitAdvanceMatching } = useFbp()
  const { pinterestTrackPurchase } = usePinterest()
  const { snapchatTrackAddPaymentInfo, snapchatTrackPurchase } = useSnapChat()
  const { ttqTrackAddBilling, ttqTrackPurchase, ttqTrackIdentify } = useTtq()
  const {
    gtagTrackAddPaymentInfo,
    gtagTrackAddShippingInfo,
    gtagTrackPurchase,
    gtagTrackConversionPurchase,
    gtagEnhanceConversion
  } = useGtag()
  const { axonTrackPurchase } = useAxon()
  const [, setLocalIsPuchase] = useLocalIsPuchase()

  const [address, setAddress, getAddressSyncedProp] = useSyncedState<CustomerAddress>(
    initAddress({})
  )
  const [, setLocalIsPaypalThankyou] = useLocalIsPaypalThankyou()

  const ref = useRef(null)
  const analytics = useAnalytics()
  const version = useVersionCookie()
  const versionRef = useRefDep(version)
  const googleConversion = `AW-${analytics?.google_conversion_id || ''}/${
    analytics?.google_conversion_label || ''
  }`
  const addressDebounced = useDebounce(address, 300)
  const addressTaxDebounced = useDebounce(address, 300)
  const [addressErrors, setAddressErrors] = useSyncedState<Types.AddressErrors>({})
  const [card] = useSyncedState<PayCreditcardRequest>(initCard())
  const [, setCardErrors] = useState<PayCreditcardRequest>({})
  const [isPaying, setIsPaying] = useState(false)
  const { logRocket } = getLogRocket()
  const paygates = usePaygates()
  const paypalEx = paygates?.paypalex
  const [isLoadStripe, setIsLoadStripe] = useState(true)
  const [isLoadBraintree, setIsLoadBraintree] = useState(true)
  // const [stripePromise] = useState(() => loadStripe(`${paygates?.creditcard?.credential_clientid}`))
  const stripePromise = useLoadStripe()
  const [isCollect] = useIsCollectTax()
  stripePromise?.catch((x) => {
    if (x) {
      setIsLoadStripe(false)
      return
    }
  })
  useEffect(() => {
    getCustomerInfo()
  }, [])
  const onBraintreeFailBack = () => {
    setIsLoadBraintree(false)
  }

  function getCustomerInfo() {
    const addr = initAddress(currentOrder?.shipping ?? localShipping ?? {})
    setAddress(addr)
  }

  async function onPay() {
    // Ignore duplicate checkout
    if (isPaying) {
      return null
    }

    setIsPaying(true)
    logger.logProductEvent('paid_credit_card')
    payByCreditCard()
  }

  const onPayStripe = async (stripe?: Stripe, elements?: StripeElements) => {
    if (isPaying) {
      return null
    }
    setIsPaying(true)
    try {
      const addr = { ...address, country: 'United States' }
      logger.logProductEvent('add_payment_info')
      await validateAddress(addr)
      ttqTrackIdentify(address)

      fbpTrackAddPaymentInfo(currentOrder?.items, currentOrder?.items_amount)
      ttqTrackAddBilling(currentOrder?.items, currentOrder?.items_amount)
      gtagTrackAddPaymentInfo(currentOrder?.items, currentOrder?.items_amount, 'Stripe')
      gtagTrackAddShippingInfo(currentOrder?.items, currentOrder?.items_amount)
      snapchatTrackAddPaymentInfo(currentOrder?.items, currentOrder?.items_amount)
      // await updateShippingAddress(addr)
      const result = await payByStripe(stripe as any, elements as any, addr)

      // TODO: Pixel
      fbpTrackPurchase(result?.order.items, result?.order.amount)
      fbpInitAdvanceMatching(result?.order?.shipping)
      pinterestTrackPurchase(result?.order.amount)
      snapchatTrackPurchase(result?.order.items, result?.order.amount)
      ttqTrackIdentify(result?.order?.shipping)
      ttqTrackPurchase(result?.order.items, result?.order.amount)
      gtagTrackPurchase(result?.order)
      axonTrackPurchase(result?.order)
      gtagTrackConversionPurchase(googleConversion, result?.order)
      gtagEnhanceConversion(result?.order)
      setLocalSissionId(genSid())
      setEventFbId(genSid())
      setLocalIsPuchase(true)
      logRocket?.identify({
        email: address.email,
        firstName: address.first_name,
        lastName: address.last_name
      })

      LocalContact.add({
        order_name: result?.order.code,
        email: address.email
      })
      // logger.logMakePayment('success')
      logger.logProductEvent('payment_success_creadit_card')
      logger.logProductEvent('payment_success')
      if (router.query?.afs || '') {
        logger.logProductEvent('aftersale_payment_success')
        logger.logProductEvent('aftersale_payment_success_stripe')
      }

      notiDispatch({
        payload: {
          content: 'Your payment was successfully',
          type: 'is-success',
          timeout: 1
        },
        type: 'REMOVE_ALL_AND_ADD'
      })
      setLocalIsPaypalThankyou(false)
      setTimeout(() => {
        const query = router.query
        delete query.vb
        delete query.subpath
        redirect('/thankyou', {
          query: { ...query, afs: result?.order.id },
          keepQuery: false
        })
      }, 1000)
    } catch (err: any) {
      if (err.addressErrors || err.cardErrors) {
        setAddressErrors(err.addressErrors || {})
        setCardErrors(err.cardErrors || {})
        const form = ref?.current as any
        if (!form) {
          return
        }
        const _error = form.querySelector('.input.is-danger')
        if (_error) {
          form
            .querySelector('.input.is-danger')
            .scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
        }
      } else {
        const message = err?.response?.data?.detail || err.message
        const errCode = err?.response?.status
        if (errCode === 404 || errCode === 402 || errCode === 550 || errCode === 555) {
          notiDispatch({
            payload: {
              content: message,
              type: 'is-danger'
            },
            type: 'REMOVE_ALL_AND_ADD'
          })
        } else {
          notiDispatch({
            payload: { content: message, type: 'is-danger' },
            type: 'REMOVE_ALL_AND_ADD'
          })
          // console.error('checkout error', err?.response?.data)
        }
      }
      logger.logProductEvent('payment_failure')
    } finally {
      setIsPaying(false)
    }
  }

  async function payByCreditCard() {
    try {
      const addr = { ...address, country: 'United States' }
      await validateAddress(addr)
      await validateCard({ ...card })
      ttqTrackIdentify(address)

      fbpTrackAddPaymentInfo(currentOrder?.items, currentOrder?.amount)
      snapchatTrackAddPaymentInfo(currentOrder?.items, currentOrder?.amount)
      ttqTrackAddBilling(currentOrder?.items, currentOrder?.amount)
      gtagTrackAddPaymentInfo(currentOrder?.items, currentOrder?.amount, 'Credit Card')
      gtagTrackAddShippingInfo(currentOrder?.items, currentOrder?.amount)
      let result: { order: Types.Order } | undefined
      // await updateShippingAddress(addr)
      if (paygates?.creditcard?.adapter === paygate_adapter.BRAINTREE) {
        result = await payByCardBraintree(card, addr)
      } else {
        result = await payByCard(card, addr)
      }

      // TODO: Pixel
      fbpTrackPurchase(result?.order.items, result?.order.amount)
      fbpInitAdvanceMatching(result?.order?.shipping)
      pinterestTrackPurchase(result?.order.amount)
      snapchatTrackPurchase(result?.order.items, result?.order.amount)
      ttqTrackIdentify(result?.order?.shipping)
      ttqTrackPurchase(result?.order.items, result?.order.amount)
      gtagTrackPurchase(result?.order)
      axonTrackPurchase(result?.order)
      gtagTrackConversionPurchase(googleConversion, result?.order)
      gtagEnhanceConversion(result?.order)
      setLocalSissionId(genSid())
      setEventFbId(genSid())
      setLocalIsPuchase(true)
      logRocket?.identify({
        email: address.email,
        firstName: address.first_name,
        lastName: address.last_name
      })

      LocalContact.add({
        order_name: result?.order.code,
        email: address.email
      })
      // logger.logMakePayment('success')
      logger.logProductEvent('payment_success_creadit_card')
      logger.logProductEvent('payment_success')
      if (router.query?.afs || '') {
        logger.logProductEvent('aftersale_payment_success_stripe')
        logger.logProductEvent('aftersale_payment_success')
      }

      notiDispatch({
        payload: {
          content: 'Your payment was successfully',
          type: 'is-success',
          timeout: 1
        },
        type: 'REMOVE_ALL_AND_ADD'
      })
      setLocalIsPaypalThankyou(false)

      setTimeout(() => {
        const query = router.query
        delete query.vb
        delete query.subpath
        redirect('/thankyou', {
          query: { ...query, afs: result?.order.id },
          keepQuery: false
        })
      }, 1000)
    } catch (err: any) {
      if (err.addressErrors || err.cardErrors) {
        setAddressErrors(err.addressErrors || {})
        setCardErrors(err.cardErrors || {})
        const form = ref?.current as any
        if (!form) {
          return
        }
        const _error = form.querySelector('.input.is-danger')
        if (_error) {
          form
            .querySelector('.input.is-danger')
            .scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
        }
      } else {
        const message = err?.response?.data?.detail || err.message
        const errCode = err?.response?.status
        if (errCode === 404 || errCode === 402 || errCode === 550 || errCode === 555) {
          notiDispatch({
            payload: {
              content: message,
              type: 'is-danger'
            },
            type: 'REMOVE_ALL_AND_ADD'
          })
        } else {
          notiDispatch({
            payload: { content: message, type: 'is-danger' },
            type: 'REMOVE_ALL_AND_ADD'
          })
          console.error('checkout error', err?.response?.data)
        }
      }
      logger.logProductEvent('payment_failure')
    } finally {
      setIsPaying(false)
    }
  }

  function onSubmit(stripe?: Stripe, elements?: StripeElements) {
    if (isPreview) {
      notiDispatch({
        type: REMOVE_ALL_AND_ADD,
        payload: {
          content: `You couldn't charge in preview mode`,
          type: 'is-warning'
        }
      })
    }
    if (!isPurchase) {
      return notiDispatch({
        type: REMOVE_ALL_AND_ADD,
        payload: {
          content: `You cannot checkout. This page is for preview only.`,
          type: 'is-danger'
        }
      })
    }
    if (!!address.postal_code?.length && !checkZipCode(address.postal_code || '')) {
      addressErrors.postal_code = 'ZIP Code unsupported'
      return notiDispatch({
        type: REMOVE_ALL_AND_ADD,
        payload: {
          content: `Sorry for the inconvenience! Our service is not available for postal code ${
            address.postal_code || ''
          }. Please correct it to checkout!`,
          type: 'is-danger'
        }
      })
    }
    if (isPaidOrder) {
      setLocalIsPaypalThankyou(false)
      const query = router.query
      delete query.vb
      delete query.subpath
      redirect('/thankyou', {
        query: { ...query, afs: currentOrder?.id },
        keepQuery: false
      })
    }
    if (paygates?.creditcard?.supportjs && isLoadStripe) {
      return onPayStripe(stripe, elements)
    } else {
      return onPay()
    }
  }
  function onSubmitBrainTree(braintreeInstance?: HostedFields, threedInstance?: ThreeDSecure) {
    if (isPreview) {
      notiDispatch({
        type: REMOVE_ALL_AND_ADD,
        payload: {
          content: `You couldn't charge in preview mode`,
          type: 'is-warning'
        }
      })
    }
    if (!isPurchase) {
      return notiDispatch({
        type: REMOVE_ALL_AND_ADD,
        payload: {
          content: `You cannot checkout. This page is for preview only.`,
          type: 'is-danger'
        }
      })
    }
    if (!!address.postal_code?.length && !checkZipCode(address.postal_code || '')) {
      addressErrors.postal_code = 'ZIP Code unsupported'
      return notiDispatch({
        type: REMOVE_ALL_AND_ADD,
        payload: {
          content: `Sorry for the inconvenience! Our service is not available for postal code ${
            address.postal_code || ''
          }. Please correct it to checkout!`,
          type: 'is-danger'
        }
      })
    }
    if (isPaidOrder) {
      setLocalIsPaypalThankyou(false)
      const query = router.query
      delete query.vb
      delete query.subpath
      redirect('/thankyou', {
        query: { ...query, afs: currentOrder?.id },
        keepQuery: false
      })
    }
    if (paygates?.creditcard?.supportjs && isLoadBraintree) {
      return onPayBraintree(braintreeInstance, threedInstance)
    } else {
      return onPay()
    }
  }

  const updateCustomerInfo = async (key: keyof CustomerAddress, value?: string) => {
    switch (key) {
      case 'email':
        logger.logCheckoutAction('email')
        break

      case 'phone':
        logger.logCheckoutAction('phone')
        break

      default:
        logger.logCheckoutAction('shipping')
        break
    }

    try {
      updateShipping(key as CustomerAddressField, value)
    } catch (err: any) {
      console.error('Error while update shipping address')
    }
  }
  useDeepEffect(
    ([currentAddress]: [CustomerAddress], [prevAddress]: [CustomerAddress]) => {
      setLocalShipping({ ...address, country: 'United States' })
      ;(Object.keys(currentAddress) as Array<keyof CustomerAddress>).forEach((key) => {
        if (currentAddress[key] !== prevAddress[key]) {
          updateCustomerInfo(key, currentAddress[key])
        }
      })
    },
    [addressDebounced]
  )
  useDeepEffect(async () => {
    try {
      if ((addressDebounced?.postal_code || '').trim().length > 3) {
        await validateAddressByTax(addressTaxDebounced)
        await updateTaxAmount(addressTaxDebounced, method_type.CREDITCARD)
      }
    } catch (error) {
      console.log(error)
    }
  }, [
    {
      address1: addressTaxDebounced.address1,
      address2: addressTaxDebounced.address2,
      city: addressTaxDebounced.city,
      state: addressTaxDebounced.state,
      postal_code: addressTaxDebounced.postal_code
    }
  ])

  useDeepEffect(
    ([currentAddress]: [CustomerAddress], [prevAddress]: [CustomerAddress]) => {
      setLocalShipping(address)
      ;(Object.keys(currentAddress) as Array<keyof CustomerAddress>).forEach((key) => {
        if (currentAddress[key] !== prevAddress[key]) {
          validateAddressField(key, currentAddress[key])
            .then(() => {
              setAddressErrors((prevAddrErrs) => {
                return {
                  ...prevAddrErrs,
                  [key]: ''
                }
              })
            })
            .catch((err) => {
              setAddressErrors((prevAddrErrs) => {
                return {
                  ...prevAddrErrs,
                  [key]: err.details?.[0]?.message
                }
              })
            })
        }
      })
    },
    [address]
  )

  useDeepEffect(
    ([currentCard]: [PayCreditcardRequest], [oldCard]: [PayCreditcardRequest]) => {
      logger.logCheckoutAction('card')
      ;(Object.keys(currentCard) as Array<keyof PayCreditcardRequest>).forEach((key) => {
        if (currentCard[key] !== oldCard[key]) {
          validateCardField(key, currentCard[key])
            .then(() => {
              setCardErrors((prevCardErrors) => {
                return {
                  ...prevCardErrors,
                  [key]: ''
                }
              })
            })
            .catch((err) => {
              setCardErrors((prevCardErrors) => {
                return {
                  ...prevCardErrors,
                  [key]: err.details?.[0]?.message
                }
              })
            })
        }
      })
    },
    [card]
  )

  const onPayBraintree = async (
    braintreeInstance?: HostedFields,
    threedInstance?: ThreeDSecure
  ) => {
    if (isPaying) {
      return null
    }
    setIsPaying(true)
    try {
      const addr = { ...address, country: 'United States' }
      await validateAddress(addr)
      ttqTrackIdentify(address)

      fbpTrackAddPaymentInfo(currentOrder?.items, currentOrder?.items_amount)
      ttqTrackAddBilling(currentOrder?.items, currentOrder?.items_amount)
      gtagTrackAddPaymentInfo(currentOrder?.items, currentOrder?.items_amount, 'Braintree')
      gtagTrackAddShippingInfo(currentOrder?.items, currentOrder?.items_amount)
      snapchatTrackAddPaymentInfo(currentOrder?.items, currentOrder?.items_amount)
      // await updateShippingAddress(addr)
      const result = await payByBraintree(braintreeInstance as any, threedInstance as any)

      // TODO: Pixel
      fbpTrackPurchase(result?.order.items, result?.order.amount)
      fbpInitAdvanceMatching(result?.order?.shipping)
      pinterestTrackPurchase(result?.order.amount)
      snapchatTrackPurchase(result?.order.items, result?.order.amount)
      ttqTrackIdentify(result?.order?.shipping)
      ttqTrackPurchase(result?.order.items, result?.order.amount)
      gtagTrackPurchase(result?.order)
      axonTrackPurchase(result?.order)
      gtagTrackConversionPurchase(googleConversion, result?.order)
      gtagEnhanceConversion(result?.order)
      setLocalSissionId(genSid())
      setEventFbId(genSid())
      setLocalIsPuchase(true)
      logRocket?.identify({
        email: address.email,
        firstName: address.first_name,
        lastName: address.last_name
      })

      LocalContact.add({
        order_name: result?.order.code,
        email: address.email
      })
      logger.logMakePayment('success')
      logger.logProductEvent('payment_success_creadit_card')
      logger.logProductEvent('payment_success')
      if (router.query?.afs || '') {
        logger.logProductEvent('aftersale_payment_success_stripe')
        logger.logProductEvent('aftersale_payment_success')
      }

      notiDispatch({
        payload: {
          content: 'Your payment was successfully',
          type: 'is-success',
          timeout: 1
        },
        type: 'REMOVE_ALL_AND_ADD'
      })
      setLocalIsPaypalThankyou(false)
      setTimeout(() => {
        const query = router.query
        delete query.vb
        delete query.subpath
        redirect('/thankyou', {
          query: { ...query, afs: result?.order.id },
          keepQuery: false
        })
      }, 1000)
    } catch (err: any) {
      setIsPaying(false)
      if (err === 'cancel') return
      if (err?.addressErrors || err?.cardErrors) {
        setAddressErrors(err.addressErrors || {})
        setCardErrors(err.cardErrors || {})
        const form = ref?.current as any
        if (!form) {
          return
        }
        const _error = form.querySelector('.input.is-danger')
        if (_error) {
          form
            .querySelector('.input.is-danger')
            .scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
        }
      } else {
        const message = err?.response?.data?.detail || err?.message
        const errCode = err?.response?.status
        if (errCode === 404 || errCode === 402 || errCode === 550 || errCode === 555) {
          notiDispatch({
            payload: {
              content: message,
              type: 'is-danger'
            },
            type: 'REMOVE_ALL_AND_ADD'
          })
        } else {
          notiDispatch({
            payload: { content: message, type: 'is-danger' },
            type: 'REMOVE_ALL_AND_ADD'
          })
          // console.error('checkout error', err?.response?.data)
        }
      }
      logger.logProductEvent('payment_failure')
    } finally {
      setIsPaying(false)
    }
  }

  return (
    <>
      <style jsx global>
        {globalStyle}
      </style>
      <form
        ref={ref}
        {...props}
        onSubmit={(ev) => {
          ev.preventDefault()
          if (
            paygates?.creditcard?.adapter === 'STRIPE' &&
            (!paygates?.creditcard?.supportjs || !isLoadStripe)
          ) {
            onSubmit()
          }
          if (
            paygates?.creditcard?.adapter === 'BRAINTREE' &&
            (!paygates?.creditcard?.supportjs || !isLoadBraintree)
          ) {
            onSubmitBrainTree()
          }
        }}
      >
        <div className={`form-block ${version === 'v13' ? 'v13' : ''} `}>
          <div className="mb-2 has-text-weight-medium">Contact information</div>
          <div className="field">
            <div className="control">
              <input
                value={address?.email ?? ''}
                name="email"
                className={`input ${addressErrors.email ? 'is-danger' : ''}`}
                disabled={isPaidOrder}
                type="email"
                placeholder={version == 'v13' ? 'Enter email' : 'Email'}
                onChange={(event) => {
                  setAddress((prevAddress) => {
                    return {
                      ...prevAddress,
                      email: event.target.value.trim()
                    }
                  })
                }}
                // onBlur={() => setTimeout(() => updateCustomerInfo('email', email), 200)}
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault()
                }}
              />
              <p className="help is-danger">{addressErrors.email}</p>
            </div>
          </div>
        </div>
        <AddressForm
          addressSyncedProp={getAddressSyncedProp()}
          errors={addressErrors}
          isPaidOrder={isPaidOrder}
          className={`form-block ${version === 'v13' ? 'v13' : ''}`}
          label="Shipping address"
          version={version}
        />
        <OtpOut />
        {((!isPaidOrder && !paygates?.creditcard?.supportjs) ||
          (!isLoadStripe && paygates?.creditcard?.adapter === paygate_adapter.STRIPE) ||
          (!isLoadBraintree && paygates?.creditcard?.adapter === paygate_adapter.BRAINTREE)) && (
          // <CardForm
          //   cardSyncedProp={getCardSyncedProp()}
          //   errors={cardErrors}
          //   className="form-block"
          //   label="Card information"
          // />
          <>
            {isShowPaypalButtonFallBack && paypalEx && (
              <PaypalButton paypalEx={paypalEx} isPurchase={isPurchase} />
            )}
          </>
        )}
        {!isPaidOrder &&
          isLoadStripe &&
          paygates?.creditcard?.supportjs &&
          paygates?.creditcard?.adapter === paygate_adapter.STRIPE && (
            <>
              <Elements
                stripe={stripePromise}
                options={{
                  fonts: [
                    {
                      cssSrc: 'https://fonts.googleapis.com/css2?family=Poppins&display=swap'
                    }
                  ]
                }}
              >
                <CardFormStripe
                  isPaidOrder={isPaidOrder}
                  isPreview={isPreview}
                  isPurchase={isPurchase}
                  currentOrder={currentOrder}
                  onStripePay={onSubmit}
                  isPaying={isPaying}
                  version={versionRef.current}
                />
              </Elements>
            </>
          )}
        {!isPaidOrder &&
          isLoadBraintree &&
          paygates?.creditcard?.supportjs &&
          paygates?.creditcard?.adapter === paygate_adapter.BRAINTREE && (
            <>
              <CardFormBraintree
                isPaidOrder={isPaidOrder}
                isPreview={isPreview}
                isPurchase={isPurchase}
                currentOrder={currentOrder}
                isPaying={isPaying}
                onBraintreePay={onSubmitBrainTree}
                onBraintreeFailBack={onBraintreeFailBack}
                version={versionRef.current}
              />
            </>
          )}

        {!isPaidOrder &&
          paygates?.creditcard?.adapter === paygate_adapter.BRAINTREE &&
          (!isLoadBraintree || !paygates?.creditcard?.supportjs) && (
            <>
              {isCollect && currentOrder?.tax_engine && (
                <div className="tax-amount">
                  {currentOrder?.tax_amount ? (
                    <>
                      <span className="tax-amount__number">
                        {getCurrency(currentOrder?.tax_amount || 0)}
                      </span>{' '}
                      of sales tax has been added to total order
                    </>
                  ) : (
                    <>
                      Based on your address, sales tax is{' '}
                      <span className="tax-amount__number">
                        {getCurrency(currentOrder?.tax_amount || 0)}
                      </span>
                    </>
                  )}
                </div>
              )}
              <button
                className={`checkout-button button is-fullwidth is-primary ${
                  isPaying ? 'is-loading' : ''
                }`}
                onClick={() => onSubmitBrainTree()}
              >
                {isPaidOrder && <strong>Next</strong>}
                {!isPaidOrder && (
                  <>
                    <figure className="image is-16x16 mr-4 mb-1">
                      <img src={iconPath('lock.svg')} alt="lock-b" />
                    </figure>
                    <strong>
                      {/* {isPaypalCredit ? 'Complete order' : `Pay ${getCurrency(amount)} now`} */}
                      {`Pay ${getCurrency(currentOrder?.amount ?? 0)} now`}
                    </strong>
                  </>
                )}
              </button>
            </>
          )}
      </form>
    </>
  )
}

const globalStyle = css.global`
  .v13 {
    input {
      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #818181;
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #818181;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #818181;
      }
      box-shadow: inset 0px 0px 8px rgba(34, 34, 34, 0.1);
      border-color: #d4d4d4;
      padding: 15px 12px;
      &.disabled {
        border-color: #d4d4d4;
        background: #e8e8e8;
      }
      @media screen and (max-width: 320px) {
        font-size: 15px;
      }
    }
    .select {
      select {
        box-shadow: inset 0px 0px 8px rgba(34, 34, 34, 0.1) !important;
        border-color: #d4d4d4 !important;
        padding: 15px 40px 15px 12px !important;
        &.placeholder {
          color: #818181 !important;
          padding: 15px 40px 15px 12px !important;
        }
      }
    }
    .control.has-prefix .prefix {
      color: #818181;
    }
  }
  .form-block {
    margin-bottom: 16px;
  }

  .checkout-footer {
    margin-top: 16px;
  }

  .tax-amount {
    text-align: center;
    font-size: 15px;
    line-height: 24px;
    color: #363636;
    padding: 0 10px 10px 10px;

    &__number {
      font-weight: 600;
    }
  }

  .checkout-button {
    height: auto;
    padding: 19px 0 18px;
  }
`
