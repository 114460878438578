import { getLocalObject, setLocalObject } from '@ui/helpers'

export class LocalContact {
  //static contactKey = 'CONTACT_FORM'
  static contactKey = '_local_contact_form'
  private static initForm = {} as Types.TicketRequest

  static get contactForm(): Types.TicketRequest {
    return process.browser ? getLocalObject(this.contactKey) || this.initForm : this.initForm
  }

  static set contactForm(form: Types.TicketRequest) {
    if (process.browser) {
      setLocalObject(this.contactKey, form)
    }
  }

  static add(form: Partial<Types.TicketRequest>) {
    this.contactForm = {
      ...this.contactForm,
      ...form
    }
  }

  static clear() {
    if (process.browser) {
      window.localStorage.removeItem(this.contactKey)
    }
  }
}

export default LocalContact
