import React from 'react'
import { Callout } from '../shared/Callout'
import css from 'styled-jsx/css'
import { useOrderService, useOtpOut } from '@libs/client'

export const OtpOut = () => {
  const { updateOtpOut } = useOrderService()
  const [otpOut, setOtpOut] = useOtpOut()

  return (
    <>
      <style jsx>{compSylte}</style>
      <div className="checkbox">
        <label className="checkbox">
          <span className="optout-label">
            I would like to receive texts about your products. (Yes, I agree to these
            <Callout
              calloutContent={
                <div>
                  I agree to receive texts that might be considered marketing and that may be sent
                  using an autodialer. I understand that checking this box is not required to
                  purchase.
                </div>
              }
            >
              <span className="otpout-term">terms</span>.)
            </Callout>
          </span>
          <input
            type="checkbox"
            name="optout"
            checked={otpOut == null || otpOut == undefined ? true : otpOut}
            onChange={() => {
              setOtpOut(!(otpOut == null || otpOut == undefined ? true : otpOut))
              updateOtpOut(!(otpOut == null || otpOut == undefined ? true : otpOut))
            }}
          />
          <span className="checkmark"></span>
        </label>
      </div>
    </>
  )
}

const compSylte = css`
  /* .checkbox {
    display: flex;
  } */
  .checkbox {
    display: block;
    position: relative;
    margin-bottom: 32px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #ffffff;
    border: 1px solid #e0e3e9;
    border-radius: 4px;
  }

  .checkbox:hover input ~ .checkmark {
    background-color: #ffffff;
  }

  .checkbox input:checked ~ .checkmark {
    background-color: #5bb3e4;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .checkbox input:checked ~ .checkmark:after {
    display: block;
  }

  .checkbox .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .optout-label {
    display: block;
    padding-left: 28px;
    font-size: 14px;
    line-height: 22px;
  }

  .otpout-term {
    text-decoration: underline;
  }
`
