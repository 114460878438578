import { loadStripe, Stripe } from '@stripe/stripe-js'
import { usePaygates } from '@ui/hooks'
import { useEffect, useState } from 'react'

export const useLoadStripe = () => {
  const paygates = usePaygates()
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null)

  useEffect(() => {
    if (!stripePromise && paygates?.creditcard?.credential_clientid) {
      const loadStripeAsync = async () => {
        try {
          const stripe = await loadStripe(`${paygates?.creditcard?.credential_clientid}`)
          setStripePromise(Promise.resolve(stripe))
        } catch (error) {
          console.error('Failed to load Stripe:', error)
          setStripePromise(Promise.resolve(null))
        }
      }
      loadStripeAsync()
    }
  }, [stripePromise, paygates])

  return stripePromise
}
