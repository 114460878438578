import { CSSProperties, PropsWithChildren, useState } from 'react'

interface ICallout {
  calloutContent?: JSX.Element
  calloutClassname?: string
  calloutDropdownStyle?: CSSProperties
}

export const Callout = ({
  children,
  calloutContent,
  calloutDropdownStyle,
  calloutClassname
}: PropsWithChildren<ICallout>) => {
  const [open, setOpen] = useState(false)

  return (
    <span
      className="callout"
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
        setOpen(true)
      }}
    >
      {children}
      {calloutContent && (
        <span
          className={`callout__dropdown ${open ? 'active' : ''} ${calloutClassname ?? ''}`}
          style={calloutDropdownStyle}
        >
          {calloutContent}
        </span>
      )}
      <style jsx>{`
        .callout {
          padding-left: 4px;
          .callout__dropdown {
            background-color: #fff;
            border-radius: 8px;
            border: 1px solid #e0e3e9;
            padding: 12px;
            opacity: 0;
            display: none;
            margin-top: 16px;
            margin-left: -28px;
            font-size: 13px;
            line-height: 22px;
            &.active {
              opacity: 1;
              display: block;
            }
          }
          &::after {
            width: 10px;
            height: 10px;
            content: '';
            border: 1px solid transparent;
            border-top-color: #e0e3e9;
            border-right-color: #e0e3e9;
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
            top: 55px;
            left: 53px;
            background-color: #fff;
            position: absolute;
            margin-top: 0;
            display: block;
            display: ${open ? 'block' : 'none'};
          }
        }
      `}</style>
    </span>
  )
}
