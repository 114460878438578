import React from 'react'
import { BraintreeError, client, hostedFields, threeDSecure, ThreeDSecure } from 'braintree-web'
import { useEffect, useRef, useState } from 'react'
import { getCurrency, iconPath } from '@ui/helpers'
import {
  HostedFields,
  HostedFieldsEvent,
  HostedFieldsField,
  HostedFieldsFieldDataFields,
  HostedFieldsHostedFieldsFieldData,
  HostedFieldsHostedFieldsFieldName
} from 'braintree-web/modules/hosted-fields'
import css from 'styled-jsx/css'
import { ICheckoutForm } from './CheckoutForm'
import { usePaygates } from '@ui/hooks'
import { tokenBrKey, useGlobalState, useIsCollectTax } from '@libs/client'

const validationField: { [key: string]: string } = {
  number: 'Card number',
  cvv: 'Card cvc',
  expirationDate: 'Card expiry'
}

type BraintreeFields = 'number' | 'expirationDate' | 'cvv'

const braintreeFields: Record<BraintreeFields, HostedFieldsField> = {
  number: {
    selector: '#number',
    placeholder: 'Card number',
    maxCardLength: 16
  },
  expirationDate: {
    selector: '#expirationDate',
    placeholder: 'Expiry date (MM / YY)'
  },
  cvv: {
    selector: '#cvv',
    placeholder: 'CVV'
  }
}

interface CardFormBraintreeProps extends ICheckoutForm {
  isPaying: boolean
  version?: string
  onBraintreePay: (braintree: HostedFields, threed: ThreeDSecure) => void
  onBraintreeFailBack: () => void
}

const CardFormBraintree = ({
  isPaidOrder,
  currentOrder,
  isPaying,
  onBraintreePay,
  onBraintreeFailBack
}: CardFormBraintreeProps) => {
  const [numberError, setNumberError] = useState('')
  const [expirationDateError, setExpirationDateError] = useState('')
  const [cvvError, setCvvError] = useState('')
  const [tokenBr] = useGlobalState<string>(tokenBrKey)
  const [braintreeInstance, setBraintreeInstance] = useState<HostedFields>()
  const paygates = usePaygates()
  const listSupportCard = paygates?.creditcard?.cards
  const [isCollect] = useIsCollectTax()

  const [threedInstance, setThreedInstance] = useState<ThreeDSecure>()
  const ref = useRef(false)
  const braintreeStyle = {
    input: {
      color: '#363636',
      'line-height': '24px',
      'font-family': 'Poppins, sans-serif',
      'font-size': '16px',
      padding: '15px 20px',
      ':focus': {
        color: '#363636'
      }
    },

    'input::placeholder': {
      'font-family': 'Poppins, sans-serif',
      'line-height': '24px',
      color: '#b5b5b5',
      'font-weight': '400',
      'font-size': '16px'
    }
  }

  const setupBraintree = async (token: string) => {
    try {
      const clientInstance = await client.create({
        authorization: token
      })
      hostedFields.create(
        {
          client: clientInstance,
          styles: braintreeStyle,
          fields: braintreeFields
        },
        async (err?: BraintreeError, hostedFields?: HostedFields) => {
          if (err) {
            console.error('Error creating instance:', JSON.stringify(err))
          }
          if (!hostedFields) {
            console.error(`Error creating 3D Secure instance not hostedField`)
            return
          }
          hostedFields?.on('empty', (event: HostedFieldsEvent) =>
            validateBraintreeField(event.fields, event.emittedBy)
          )

          hostedFields?.on('validityChange', (event: HostedFieldsEvent) =>
            validateBraintreeField(event.fields, event.emittedBy)
          )
          hostedFields?.on('blur', (event: HostedFieldsEvent) =>
            validateBraintreeField(event.fields, event.emittedBy)
          )
          setBraintreeInstance(hostedFields)
          threeDSecure.create(
            {
              client: clientInstance,
              version: '2'
            },
            (err?: BraintreeError, threeDSecureInstance?: ThreeDSecure) => {
              if (err) {
                console.error(
                  'Error creating 3D Secure instance:',
                  JSON.stringify(err),
                  threeDSecure
                )
                return
              }
              if (threeDSecureInstance) {
                setThreedInstance(threeDSecureInstance)
              }
            }
          )
        }
      )
    } catch (error) {
      onBraintreeFailBack()
      console.log(error)
    }
  }

  // hostedFields.
  useEffect(() => {
    if (process.browser && tokenBr && !ref.current) {
      ref.current = true
      setupBraintree(tokenBr)
    }
  }, [tokenBr])

  function validateBraintreeField(
    fields: HostedFieldsFieldDataFields,
    emit: HostedFieldsHostedFieldsFieldName | string
  ) {
    switch (emit) {
      case 'number':
        setNumberError(
          fields?.[emit]?.isEmpty
            ? `"${validationField?.[emit]}" is not allowed to be empty`
            : !fields?.[emit]?.isPotentiallyValid || !fields?.[emit]?.isValid
            ? `"${validationField?.[emit]}" is not valid`
            : ''
        )
        break
      case 'expirationDate':
        setExpirationDateError(
          fields?.[emit]?.isEmpty
            ? `"${validationField?.[emit]}" is not allowed to be empty`
            : !fields?.[emit]?.isPotentiallyValid || !fields?.[emit]?.isValid
            ? `"${validationField?.[emit]}" is not valid`
            : ''
        )
        break
      case 'cvv':
        setCvvError(
          fields?.[emit]?.isEmpty
            ? `"${validationField?.[emit]}" is not allowed to be empty`
            : !fields?.[emit]?.isPotentiallyValid || !fields?.[emit]?.isValid
            ? `"${validationField?.[emit]}" is not valid`
            : ''
        )
        break
      default:
        break
    }
  }
  const checkValidate = (fields: HostedFieldsFieldDataFields) => {
    return Object.values(fields).every(
      (field: HostedFieldsHostedFieldsFieldData) =>
        !field.isEmpty && field.isPotentiallyValid && field.isValid
    )
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    if (!braintreeInstance || !threedInstance) return
    if (checkValidate(braintreeInstance.getState().fields)) {
      onBraintreePay(braintreeInstance, threedInstance)
    } else {
      Object.keys(braintreeInstance.getState().fields).forEach((key) =>
        validateBraintreeField(braintreeInstance.getState().fields, key)
      )
    }
  }

  return (
    <div style={{ marginBottom: '20px' }}>
      <style jsx>{brStyle}</style>

      <div className="card-infomation mb-3 ">
        <div style={{ fontSize: '15px' }}>Card information</div>
        <div>
          {listSupportCard &&
            listSupportCard.map((card) => (
              <figure key={card} className="label__icon image is-pulled-right">
                <img src={iconPath(`${card.toLowerCase()}.svg`)} alt="card-list" />
              </figure>
            ))}
        </div>
      </div>

      <div
        id="number"
        className={`braintree-input card-number-input ${numberError ? 'not-valid' : ''}`}
      ></div>
      <p className="error-text">{numberError}</p>
      <div className="form-row">
        <div className="input-group">
          <div
            id="expirationDate"
            className={`braintree-input expiration-date-input ${
              expirationDateError ? 'not-valid' : ''
            }`}
          ></div>
          <p className="error-text">{expirationDateError}</p>
        </div>
        <div className="input-group">
          <div
            id="cvv"
            className={`braintree-input cvv-input ${cvvError ? 'not-valid' : ''}`}
          ></div>
          <p className="error-text">{cvvError}</p>
        </div>
      </div>

      <div className="checkout-footer">
        {isCollect && currentOrder?.tax_engine && (
          <div className="tax-amount">
            {currentOrder?.tax_amount ? (
              <>
                <span className="tax-amount__number">
                  {getCurrency(currentOrder?.tax_amount || 0)}
                </span>{' '}
                of sales tax has been added to total order
              </>
            ) : (
              <>
                Based on your address, sales tax is{' '}
                <span className="tax-amount__number">
                  {getCurrency(currentOrder?.tax_amount || 0)}
                </span>
              </>
            )}
          </div>
        )}
        <button
          className={`checkout-button button is-fullwidth is-primary ${
            isPaying ? 'is-loading' : ''
          }`}
          disabled={!braintreeInstance || !threedInstance}
          onClick={handleSubmit}
        >
          {isPaidOrder && <strong>Next</strong>}
          {!isPaidOrder && (
            <>
              <figure className="image is-16x16 mr-4 mb-1">
                <img src={iconPath('lock.svg')} alt="lock" />
              </figure>
              <strong>{`Pay ${getCurrency(currentOrder?.amount ?? 0)} now`}</strong>
            </>
          )}
        </button>
      </div>
    </div>
  )
}

export default CardFormBraintree

const brStyle = css`
  .form-title {
    font-size: 15px;
  }
  .braintree-input {
    border: 1px solid rgb(219, 219, 219);
    height: 55.6px;
    border-radius: 4px;
    box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  }
  .braintree-input.not-valid {
    border: 1px solid rgb(241, 70, 104);
  }

  .form-row {
    display: flex;
    justify-content: space-between;
  }

  .input-group {
    width: 49.3%;
    margin-top: 10px;
  }

  .card-number-input {
    margin-top: 10px;
  }

  .error-text {
    margin-top: 4px;
    color: rgb(241, 70, 104);
    font-size: 0.74rem;
    font-family: Poppins, sans-serif;
    margin-bottom: 0px;
  }

  .card-infomation {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-weight: 500;
    font-size: 1rem;
    line-height: 24px;
    .label__icon {
      &:not(:last-child) {
        margin-left: 6px;
      }

      img {
        width: unset;
        height: 20px;
      }
    }

    .checkout-footer {
      margin-top: 25px;
    }

    .tax-amount {
      text-align: center;
      font-size: 15px;
      line-height: 24px;
      color: #363636;
      padding: 0 10px 10px 10px;

      &__number {
        font-weight: 600;
      }
    }
  }
`
